'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { CrossLine, Image2Line, RefreshCwLine } from '@zealy/icons';
import { Button } from '../../../Button';
import { ProgressBar } from '../../../ProgressBar';
import { sizeFormatter } from '../../FileUpload.const';
import { ALLOWED_IMAGES, FILE_UPLOAD_ERRORS } from './FileCard.const';
import { getIconByType } from './FileCard.funcs';
import * as styles from './FileCard.styles';
const Separator = () => (_jsx("span", { className: "body-component-md text-file-upload-tertiary", children: ' - ' }));
export const FileCard = ({ file, progress = 0, error, isUploaded, isUploading, onRemove, onRetry, doneLabel = 'Done', onItemRemoved, }) => {
    const size = useMemo(() => sizeFormatter.format(file.size), [file.size]);
    const icon = useMemo(() => getIconByType(file.type), [file.type]);
    const isImage = useMemo(() => ALLOWED_IMAGES.indexOf(file.type) !== -1, [file.type]);
    const objectURL = useMemo(() => {
        if (isImage) {
            return URL.createObjectURL?.(file);
        }
        return '';
    }, [file, isImage]);
    const handleRemove = () => {
        onRemove?.(file);
        onItemRemoved?.({ file });
    };
    return (_jsxs("div", { className: styles.container, children: [isImage && (_jsxs("div", { className: styles.image.container, children: [_jsx(Image2Line, { className: styles.image.icon }), _jsx("div", { className: styles.image.wrapper, children: _jsx("img", { className: styles.image.source, draggable: "false", src: objectURL, alt: file.name }) })] })), _jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.cardInfo.container, children: [_jsx("div", { className: styles.cardInfo.iconContainer, children: icon({
                                    className: styles.cardInfo.icon,
                                }) }), _jsxs("div", { className: styles.cardInfo.infoWrapper, children: [_jsx("span", { className: styles.cardInfo.fileName, children: file.name }), _jsxs("span", { children: [!error && _jsx("span", { className: styles.cardInfo.sizeLabel, children: size }), isUploading && (_jsxs(_Fragment, { children: [_jsx(Separator, {}), _jsxs("span", { className: styles.cardInfo.progressLabel, children: [progress, "%"] })] })), !error && isUploaded && (_jsxs(_Fragment, { children: [_jsx(Separator, {}), _jsx("span", { className: styles.cardInfo.fallbackLabel({ success: true }), children: doneLabel })] })), error && !isUploading && (_jsx("span", { className: styles.cardInfo.fallbackLabel({ error: true }), children: error.message }))] })] }), _jsxs("div", { className: styles.cardInfo.commands.container, children: [error && error === FILE_UPLOAD_ERRORS.FAILED_TO_UPLOAD && (_jsx(Button, { size: "sm", onlyIcon: true, onClick: () => onRetry?.(file), variant: "ghost", leftIcon: _jsx(RefreshCwLine, {}), "aria-label": "Retry upload" })), _jsx(Button, { size: "sm", onlyIcon: true, onClick: handleRemove, variant: "ghost", leftIcon: _jsx(CrossLine, {}), "aria-label": "Remove file" })] })] }), _jsx(ProgressBar, { max: 100, value: progress, size: "sm", hideLabels: true })] })] }));
};
