import { cx } from 'class-variance-authority';
export const container = cx([
    'w-full flex flex-col items-center text-center select-none py-[56px] gap-[24px]',
    'group-data-[is-dragging=true]/file-upload:pointer-events-none',
]);
export const content = cx([
    'flex flex-col items-center text-center gap-file-upload-file-card-info-x',
]);
export const messageStyles = cx([
    'file-upload-dropzone-lg text-secondary',
    'group-data-[disabled=true]/file-upload:text-disabled',
]);
export const captionStyles = cx([
    'text-file-upload-dropzone-md font-light text-secondary',
    'group-data-[disabled=true]/file-upload:text-disabled',
]);
export const icon = cx([
    'icon-brand-primary group-data-[disabled=true]/file-upload:icon-disabled-solid',
]);
