import { FILE_UPLOAD_ERRORS } from './components/FileCard/FileCard.const';
/**
 * Convert a DataTransferItem array to a File array
 * @param items array of DataTransferItem
 * @returns valid files array
 */
export const dataTransferToFileArray = function (items) {
    const files = [];
    items.forEach(item => {
        if (item.kind === 'file') {
            const file = item.getAsFile();
            file && files.push(file);
        }
    });
    return files;
};
/**
 * Validate and return an array of FileCardProps
 * based on component settings.
 * @param currentFiles current files array
 * @param newFiles new files array to be added
 * @param config component settings (props)
 * @returns array of FileCardProps
 */
export const getArrayOfFileData = function (currentFiles, newFiles, config) {
    const fileDataArray = [];
    newFiles.forEach(file => {
        if (config.fileTypes.length > 0 && !config.fileTypes.includes(file.type)) {
            return;
        }
        const error = file.size > config.sizeLimit ? FILE_UPLOAD_ERRORS.FILE_TOO_BIG : undefined;
        fileDataArray.push({ file, error });
    });
    const limitDiff = config.limit ? config.limit - currentFiles.length : Infinity;
    const filesWithLimit = limitDiff ? fileDataArray.slice(0, limitDiff) : fileDataArray;
    return filesWithLimit;
};
