import { toast } from '#components/Toaster';

/**
 * Simple hook to return toast instance from Sonner. See documentation in link below for how to use
 * `toast` instance.
 * @see https://sonner.emilkowal.ski/
 */
export function useToast() {
  return toast;
}
