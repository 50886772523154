import { useEffect, useRef, useState } from 'react';

import { canUseDOM } from '@zealy/utils';

// This function runs on dependency change but not on initial render.
export const useDidMount = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted;
};

/**
 * Executes the callback function once upon the current component being mounted in the DOM
 * @param fn
 */
export function useOnMounted(fn: VoidFunction, dependencies: unknown[] = []) {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!canUseDOM()) return;
    if (isMounted.current) return;
    else {
      fn();
      isMounted.current = true;
    }
  }, [fn, ...dependencies]);
}
