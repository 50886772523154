import { cva } from 'class-variance-authority';
export const containerStyles = cva(['group/file-upload p-300 rounded-md transition ease-in-out duration-300'], {
    variants: {
        isDragging: {
            true: 'bg-file-upload-drag-over',
        },
        disabled: {
            true: 'bg-file-upload-disabled cursor-not-allowed',
        },
    },
});
export const itemsContainerStyles = cva(['flex flex-col gap-file-upload-file-card-info-y']);
