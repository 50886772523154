import { useMemo } from 'react';
import { containerStyles, itemsContainerStyles } from './FileUpload.styles';
export const useStyles = ({ isDragging, disabled, }) => {
    const container = useMemo(() => containerStyles({ isDragging, disabled }), [isDragging, disabled]);
    return {
        container,
        list: {
            container: itemsContainerStyles(),
        },
    };
};
