/**
 * Size formatter to be used in the FileCard component. By default
 * in english and in compact notation.
 */
export const sizeFormatter = Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
});
/**
 * Default size limit for the file (2.5MB)
 */
export const DEFAULT_FILE_SIZE = 2500000;
