import { cva, cx } from 'class-variance-authority';
export const container = cx([
    'p-file-upload-file-card rounded-file-upload-file-card-container border border-component-tertiary flex flex-col gap-150 min-w-0',
]);
export const content = cx([
    'p-file-upload-file-card flex flex-col gap-file-upload-file-card-info-y min-w-0',
]);
export const cardInfo = {
    container: cx('flex flex-row gap-file-upload-file-card-info-x relative min-w-0'),
    iconContainer: cx([
        'shrink-0 w-file-upload-file-card-icon-container h-file-upload-file-card-icon-container bg-component-secondary rounded-file-upload-file-card-file-type flex items-center justify-center',
    ]),
    icon: 'h-file-upload-icon-file-type w-file-upload-icon-file-type icon-primary flex-shrink-0',
    infoWrapper: cx(['flex flex-1 flex-col min-w-0']),
    fileName: cx(['flex-1 truncate text-file-upload-primary pr-500 line-clamp-2 min-w-0']),
    sizeLabel: cx(['body-component-md text-file-upload-tertiary']),
    progressLabel: cx(['body-component-md text-file-upload-primary']),
    fallbackLabel: cva(['body-component-md'], {
        variants: {
            error: {
                true: cx(['text-file-upload-file-error']),
            },
            success: {
                true: cx(['text-file-upload-file-success']),
            },
        },
    }),
    commands: {
        container: cx(['flex flex-row gap-100 -mt-100 -mr-100']),
    },
};
export const image = {
    container: cx([
        'bg-component-secondary aspect-video rounded-file-upload-file-card-img items-center justify-center flex relative overflow-hidden',
    ]),
    icon: cx(['icon-file-upload-disabled h-400 w-400']),
    wrapper: cx(['absolute inset-0']),
    source: cx(['object-cover w-full h-full']),
};
