'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '../../utils/cn';
import { formControlStyles } from '../FormControl/FormControl.styles';
import { TaskCard } from '../TaskCard';
import { EmptyState } from './components/EmptyState';
import { FileCard } from './components/FileCard';
import { DEFAULT_FILE_SIZE } from './FileUpload.const';
import { dataTransferToFileArray, getArrayOfFileData } from './FileUpload.funcs';
import { useStyles } from './FileUpload.hooks';
const FileUploadItems = ({ items, FileItemElement, onRemove, onItemRemoved, onItemChanged, onRetry, className, ...rest }) => {
    if (items.length === 0) {
        return null;
    }
    return (_jsx("div", { className, children: items.map(({ file, error }) => (_jsx(FileItemElement, { file, error, onRemove, onItemRemoved, onItemChanged, onRetry, ...rest }, file.name))) }));
};
/**
 * ### FileUpload
 * A component that allows users to upload files. It can be used to
 * upload single or multiple files. It also allows users to upload
 * files by dragging and dropping them into the component. The component
 * also provides a preview of the files that are being uploaded.
 *
 * #### Usage
 * ```jsx
 * import { FileUpload } from '@zealy/design-system';
 *
 * // Allow to upload only 5 jpg or png files
 * const App = () => {
 *  return (
 *    <FileUpload
 *      title='Upload Files'
 *      sizeLimit={1000000}
 *      fileTypes={['image/jpeg', 'image/png']}
 *      limit={5} />
 *  );
 * };
 * ```
 */
export const FileUpload = ({ title, emptyStateProps, className, keepEmptyStateVisible, disabled, limit, EmptyStateElement = EmptyState, FileItemElement = FileCard, sizeLimit = DEFAULT_FILE_SIZE, fileTypes = [], onChange, onItemRemoved, url, error, ...rest }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [items, setItems] = useState([]);
    const inputRef = useRef(null);
    const styles = useStyles({ isDragging, disabled });
    const setDraggingOn = () => !disabled && setIsDragging(true);
    const setDraggingOff = () => !disabled && setIsDragging(false);
    const addFiles = (newFiles) => {
        setItems([...items, ...newFiles]);
    };
    const onRemove = useCallback((file) => {
        setItems(i => i.filter(x => x.file.name !== file.name));
        if (inputRef.current)
            inputRef.current.value = '';
    }, []);
    const onItemChanged = useCallback((item) => {
        setItems(i => i.map(x => {
            if (x.file.name === item.file.name) {
                return { ...x, ...item };
            }
            return x;
        }));
    }, []);
    const onRetry = useCallback((file) => {
        // TODO (A-1218): Implement retry behavior on FileUpload component
        // not used right now because i want
        // to have sure that the file is valid
    }, []);
    const onClick = () => {
        if (disabled)
            return;
        inputRef.current?.click();
    };
    const updateFiles = e => {
        e.preventDefault();
        if (disabled)
            return;
        setDraggingOff();
        const unValidatedFiles = dataTransferToFileArray([...e.dataTransfer.items]);
        const validatedFiled = getArrayOfFileData(items.map(i => i.file), unValidatedFiles, { fileTypes, sizeLimit, limit });
        addFiles(validatedFiled);
    };
    const onFileChange = (e) => {
        e.preventDefault();
        if (disabled)
            return;
        if (e.target.files) {
            addFiles(getArrayOfFileData(items.map(i => i.file), [...e.target.files], {
                fileTypes,
                sizeLimit,
                limit,
            }));
        }
    };
    const shouldShowEmptyState = items.length === 0 || keepEmptyStateVisible;
    useEffect(() => {
        onChange?.(items);
    }, [items]);
    return (_jsxs(TaskCard, { type: "file", onDragLeave: e => {
            e.preventDefault();
            setDraggingOff();
        }, onDragEnter: e => {
            e.preventDefault();
            setDraggingOn();
        }, onDragOver: e => e.preventDefault(), onDrop: updateFiles, title: title, isDashed: true, className: cn(styles.container, className), "data-is-dragging": isDragging, "data-disabled": disabled, ...rest, children: [_jsx("input", { onChange: onFileChange, ref: inputRef, id: "file-uploader-input", type: "file", multiple: true, hidden: true, accept: fileTypes.join(', ') }), shouldShowEmptyState && (_jsx(EmptyStateElement, { sizeLimit, onClick, ...emptyStateProps })), _jsx(FileUploadItems, { items, FileItemElement, onRemove, onItemChanged, onItemRemoved, onRetry, url, className: styles.list.container }), error && _jsx("p", { className: formControlStyles.hint({ state: 'error' }), children: error })] }));
};
FileUpload.FileCard = FileCard;
FileUpload.EmptyState = EmptyState;
