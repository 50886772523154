import { EXTENSION_ICONS } from './FileCard.const';
/**
 * Get the icon to be used in the file type
 * @param type file type
 * @returns valid react component icon
 */
export function getIconByType(type) {
    if (type.includes('html')) {
        return EXTENSION_ICONS.HTML;
    }
    if (type.includes('image')) {
        return EXTENSION_ICONS.IMAGE;
    }
    return EXTENSION_ICONS.DEFAULT;
}
