import React, { useCallback, useEffect } from 'react';
import { useFormatter, useTranslations } from 'next-intl';

import type { FileUploadItem, FileUploadProps } from '@zealy/design-system';
import { FileUpload as Base, Link } from '@zealy/design-system';
import { deleteUserFile, useUserUpload } from '@zealy/queries';

import { useToast } from '#components/Toaster/useToast';
import { compressFile } from '#utils/compressFile';

const IMAGE_TYPES = ['image/bmp', 'image/gif', 'image/jpeg', 'image/png', 'image/webp'] as const;

export const EmptyMessage = () => {
  const t = useTranslations('quest.image-upload.empty-state');

  return (
    <>
      {t.rich('message', {
        link: children => (
          <Link
            as="button"
            className="file-upload-dropzone-lg-bold group-data-[disabled=true]/file-upload:text-disabled group-data-[disabled=true]/file-upload:border-link-disabled"
            color="cta"
            underlined
          >
            {children}
          </Link>
        ),
        strong: children => (
          <span className="file-upload-dropzone-lg-bold text-file-upload-primary  group-data-[disabled=true]/file-upload:text-disabled">
            {children}
          </span>
        ),
      })}
    </>
  );
};

export const EmptyCaption = ({ sizeLimit }: { sizeLimit: number }) => {
  const t = useTranslations('quest.image-upload.empty-state');
  const formatter = useFormatter();
  const size = formatter.number(sizeLimit, {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
  });
  return t('caption', { sizeLimit: size });
};

export const FileUploader = (props: FileUploadItem) => {
  const { data, mutate, isPending: isUploading, progress, cancel } = useUserUpload(props.url);
  const toast = useToast();

  const isUploaded = !isUploading && Boolean(data);

  const postData = useCallback(
    async (file: File) => {
      const compressedFile =
        IMAGE_TYPES.includes(file.type as any) && file.type !== 'image/gif'
          ? await compressFile(file)
          : file;

      const formData = new FormData();
      formData.append('file', compressedFile);
      mutate(
        { formData },
        {
          onSuccess: res => {
            props.onItemChanged?.({ ...props, isUploaded: true, isUploading: false, ...res });
          },
          onError: err => {
            if (err != null) {
              toast.error(err.message);
            }
          },
        },
      );
    },
    [mutate, props],
  );

  useEffect(() => {
    if (props.file) {
      postData(props.file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.file]);

  const onItemRemoved = async () => {
    if (isUploading) cancel();
    else if (data?.url) {
      await deleteUserFile(data.url);
    }
    props.onRemove?.(props.file);
  };

  return (
    <Base.FileCard
      {...props}
      {...{ onItemRemoved, progress, onRetry: postData, isUploaded, isUploading }}
    />
  );
};

export const FileUpload = (props: FileUploadProps) => {
  return (
    <Base
      {...{
        FileItemElement: FileUploader,
        emptyStateProps: {
          message: EmptyMessage,
          caption: EmptyCaption,
        },
        ...props,
      }}
    />
  );
};
