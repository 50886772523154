import { FileCodeLine, FileLine, Image3Line } from '@zealy/icons';
/**
 * Extension icons for the file card component, to customize the icon
 * based on the file extension.
 */
export const EXTENSION_ICONS = {
    IMAGE: Image3Line,
    HTML: FileCodeLine,
    DEFAULT: FileLine,
};
/**
 * Errors that can occur while uploading a file. These errors are
 * displayed in the file card component. The error message is
 * used to match and conditionally render fallback message.
 */
export const FILE_UPLOAD_ERRORS = {
    FILE_TOO_BIG: new Error('File too big'),
    FAILED_TO_UPLOAD: new Error('Failed to upload'),
};
/**
 * Default image extensions allowed to render the preview.
 */
export const ALLOWED_IMAGES = ['image/png', 'image/jpeg', 'image/gif'];
