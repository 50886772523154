import Compressor from 'compressorjs';

/**
 * Function to compress a file
 * @param file
 * @param onSuccess
 * @param maxWidth
 * @param quality
 * @returns compressed file
 */
export const compressFile = (file: File, maxWidth = 2400, quality = 0.8): Promise<File> => {
  return new Promise((resolve, reject) => {
    const compressor = new Compressor(file, {
      maxWidth,
      quality,
      success: blob => {
        resolve(
          new File([blob], file.name, {
            type: blob.type,
          }),
        );
      },
      error: err => {
        reject(err);
      },
    });
  });
};
